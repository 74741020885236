import { Link } from 'react-router-dom';
import hero from '../assets/hero-app.svg';

export default function Layout({ children }) {
  return (
    <>
      <div className="tw-bg-[hsl(262deg,100%,12.8%)] tw-py-3 tw-px-6 tw-text-center tw-font-medium">
        <Link to="/ai" className="tw-text-white tw-underline">
          Up your tech game with .AI domains, exclusive to Namecheap Auctions.
          Bid now →
        </Link>
      </div>
      <section className="gb-hero tw-z-0">
        <div className="gb-hero__image-holder">
          <img alt="" src={hero} />
        </div>
        <div className="gb-hero__body">
          <h1>Market</h1>
          <p>Discover domains for sale in our Auctions and Buy Now listings</p>
        </div>
      </section>
      {children}
    </>
  );
}
