import dayjs from 'dayjs';
import _upperFirst from 'lodash/upperFirst';
import formatCurrency from './formatCurrency';

function getNameComponents(name = '') {
  const split = name.split('.');
  return [split.shift(), split.join('.')];
}

export default function getSalePresenter(sale) {
  return {
    ...(sale?.product ?? {}),
    ...sale,

    get ageYears() {
      return sale.product?.registeredDate
        ? dayjs().diff(sale.product.registeredDate, 'years')
        : null;
    },

    get ageYearsFormatted() {
      if (this.ageYears === 0) {
        return 'Less than a year old';
      }
      return `${this.ageYears} ${this.ageYears === 1 ? 'year' : 'years'} ago`;
    },

    get bids() {
      return [...(sale.bids || [])].sort((a, b) => b.index - a.index);
    },

    get isEnded() {
      if (!sale.endDate) {
        return false;
      }
      return new Date(sale.endDate) < new Date();
    },

    get isRecentlyEnded() {
      // Return if sale ended in the last 5 minutes
      return (
        this.isEnded &&
        new Date(sale.endDate).getTime() >= new Date().getTime() - 300 * 1000
      );
    },

    get secondsRemaining() {
      if (!sale.endDate || this.isEnded) {
        return 0;
      }
      const now = new Date().getTime();
      const endDate = new Date(sale.endDate).getTime();
      const diffMs = endDate - now;
      return Math.floor(diffMs / 1000);
    },

    get minBidFormatted() {
      if (sale.minBid) {
        return formatCurrency(sale.minBid);
      }
      return null;
    },

    get nameFormatted() {
      const components = getNameComponents(sale.product?.name);
      return (
        <>
          {components[0] + '.'}
          <span className="tw-inline-block">{components[1]}</span>
        </>
      );
    },

    get nameLength() {
      return sale.product?.name.split('.')[0].length;
    },

    get permalink() {
      if (!sale.id) {
        throw new Error('Attempted to get permalink for sale without id');
      }
      if (this.saleType === 'buy') {
        return `/buynow/${sale.product.name}/`;
      }
      if (['cancelled', 'sold'].includes(this.status)) {
        return `/sale/${sale.id}/${sale.product.name}/`;
      }
      return `/${sale.product.name}/`;
    },

    get canonical() {
      let path;
      if (!sale.id) {
        throw new Error('Attempted to get permalink for sale without id');
      }
      if (this.saleType === 'buy') {
        path = `/buynow/${sale.product.name}/`;
      } else {
        path = `/sale/${sale.id}/`;
      }
      return window.location.origin + '/market' + path;
    },

    get leadingBid() {
      return this.bids.find(bid => bid.isLeadingBid);
    },

    get priceFormatted() {
      if (sale.price) {
        return formatCurrency(sale.price);
      }
      return null;
    },

    get renewPriceFormatted() {
      if (sale.renewPrice) {
        return formatCurrency(sale.renewPrice);
      }
      return null;
    },

    get statusFormatted() {
      // Active sales need no status badge
      if (sale.status === 'active') {
        return '';
      }

      return _upperFirst(sale.status);
    },

    get timeLeft() {
      if (!sale.endDate) return '';
      const now = new Date();
      const endDate = new Date(sale.endDate);
      if (endDate < now) {
        return 'Ended';
      }
      return dayjs().to(dayjs(endDate), true);
    },

    get tld() {
      const components = getNameComponents(sale.product?.name);
      return components[1];
    },

    get yearRegistered() {
      return sale.product?.registeredDate
        ? dayjs(sale.product.registeredDate).year()
        : '';
    },

    get endDateExtendedByFormatted() {
      const duration = dayjs.duration(sale.endDateExtendedMs);

      let days = Math.floor(duration.asDays());
      let hours = duration.hours();

      // Special case: show "24 hours" instead of "1 day"
      if (days === 1 && hours === 0 && duration.asHours() >= 24) {
        hours = Math.floor(duration.asHours());
        days = 0;
      }

      const parts = [
        { value: days, unit: 'day' },
        { value: hours, unit: 'hour' },
        { value: duration.minutes(), unit: 'min' },
        { value: duration.seconds(), unit: 'sec' },
      ];

      // Filter out zero values and create formatted strings
      return parts
        .filter(part => part.value > 0)
        .map(part => `${part.value} ${part.unit}${part.value !== 1 ? 's' : ''}`)
        .join(', ');
    },
  };
}
