import cx from 'classnames';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import _mapValues from 'lodash/mapValues';
import _pick from 'lodash/pick';
import { useMemo } from 'react';
import { ReactComponent as ExportIcon } from '../assets/icons/download.svg';
import { ReactComponent as WatchIcon } from '../assets/icons/star.svg';
import useWaitCursor from '../lib/useWaitCursor';
import useFavoriteMutation from '../lib/useFavoriteMutation';
import AddToCartButton from './AddToCartButton';
import Checkbox from './Checkbox';
import { useSaleTable } from './SaleTable';

export default function SaleTableActions({
  className,
  showAddToCart = false,
  showWatch = true,
}) {
  const {
    flatRows,
    getToggleAllRowsSelectedProps,
    selectedFlatRows,
    visibleColumns,
  } = useSaleTable();
  const csvColumns = useMemo(
    () => [...visibleColumns.filter(column => column.canExport ?? true)],
    [visibleColumns],
  );
  const exporter = useMemo(
    () =>
      new ExportToCsv({
        filename: 'export',
        headers: [
          ...csvColumns.map(column => column.csvHeader || column.id),
          'permalink',
        ],
        showLabels: true,
      }),
    [csvColumns],
  );
  const [favorite, { loading }] = useFavoriteMutation();

  useWaitCursor(loading);

  const allFavorited =
    selectedFlatRows.length > 0 &&
    selectedFlatRows.every(row => row.original.isFavorited);

  return (
    <div
      className={cx(
        className,
        'tw-hidden md:tw-flex tw-flex-wrap tw-items-center md:tw-py-2',
        'tw-text-sm',
        selectedFlatRows.length === 0 ? 'tw-text-gray-500' : 'tw-text-gray-700',
      )}>
      <div className="tw-mr-4 tw-h-12 tw-flex tw-flex-wrap tw-items-center tw-font-bold">
        <Checkbox
          className="tw-mx-2 tw--mt-px"
          title="Select all"
          id="selectall"
          {...getToggleAllRowsSelectedProps()}
        />
        <label
          className={cx(
            'tw-mr-2 tw-cursor-pointer',
            selectedFlatRows.length === 0
              ? ''
              : 'hover:tw-text-secondary-dark ',
          )}
          htmlFor="selectall">
          Select all
        </label>
        {showAddToCart && (
          <AddToCartButton
            sales={selectedFlatRows
              .filter(
                row =>
                  row.original.saleType === 'buy' ||
                  row.original.selfHasActiveInvoice,
              )
              .map(row => row.original)}>
            {({ onClick, inCart }) => (
              <SaleTableActionButton
                disabled={inCart || selectedFlatRows.length === 0}
                onClick={onClick}>
                <i className="gb-icon gb-icon-morpheus-cart-add tw-relative tw--top-1 tw-w-5 tw-h-5 tw-text-xl tw-mr-1" />
                Add to cart
              </SaleTableActionButton>
            )}
          </AddToCartButton>
        )}
        {showWatch && (
          <SaleTableActionButton
            disabled={loading || selectedFlatRows.length === 0}
            onClick={async () => {
              try {
                await favorite({
                  [allFavorited ? 'remove' : 'add']: selectedFlatRows.map(
                    row => row.original.id,
                  ),
                });
              } catch (error) {
                window.alert(
                  `Failed to ${
                    allFavorited
                      ? 'remove domains from favorites.'
                      : 'add domains to favorites.'
                  }`,
                );
              }
            }}>
            <WatchIcon className="tw-w-5 tw-h-5 tw-mr-1 tw--mt-px" />
            {allFavorited ? 'Remove from favorites' : 'Add to favorites'}
          </SaleTableActionButton>
        )}
        <div className="tw-hidden md:tw-flex">
          <SaleTableActionButton
            disabled={selectedFlatRows.length === 0}
            onClick={() => {
              const columnIds = csvColumns.map(column => column.id);
              exporter.generateCsv(
                selectedFlatRows.map(row => ({
                  ..._mapValues(
                    _pick(row.values, columnIds),
                    value => value ?? '',
                  ),
                  permalink:
                    row.original.saleType === 'buy'
                      ? `https://www.namecheap.com/market/buynow/${row.original.name}/`
                      : `https://www.namecheap.com/market/sale/${row.original.id}/`,
                })),
              );
            }}>
            <ExportIcon className="tw-w-5 tw-h-5 tw-mr-1 tw--mt-px" />
            Export to CSV
          </SaleTableActionButton>
        </div>
      </div>
      {selectedFlatRows.length > 0 && (
        <div className="tw-hidden md:tw-block tw-w-full sm:tw-w-auto tw-py-2 tw-font-light tw-text-gray-600">
          {selectedFlatRows.length === flatRows.length ? (
            <>
              All <b>{flatRows.length}</b> rows on this page are selected.
            </>
          ) : (
            <>
              <b>{selectedFlatRows.length}</b>{' '}
              {selectedFlatRows.length > 1 ? 'rows are' : 'row is'} selected.
            </>
          )}
        </div>
      )}
    </div>
  );
}

const SaleTableActionButton = ({ className, disabled, ...props }) => (
  <button
    className={cx(
      className,
      'hover:tw-text-secondary-dark tw-inline-flex tw-items-center tw-mx-2 focus:tw-outline-none focus-visible:tw-ring-2',
      {
        'tw-pointer-events-none': disabled,
      },
    )}
    disabled={disabled}
    type="button"
    {...props}
  />
);
