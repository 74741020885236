import cx from 'classnames';
import dayjs from 'dayjs';
import HandshakeTLDs from '@nc-aftermarket/shared/HandshakeTLDs.json';
import { useContext, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import formatCurrency from '../lib/formatCurrency';
import queryDefs from '../lib/queryDefs';
import CollectionContext from './CollectionContext';
import {
  facetFilterType,
  FacetFilter,
  SliderFilter,
  SliderFilterCurrency,
  TLDFilter,
  BidCountFacetFilter,
} from './SaleTable.filters';
import AddToCartButton from './AddToCartButton';
import Badge from './Badge';
import TimeLeft from './TimeLeft';
import Button from './Button';

export const SaleTableColumnSets = {
  auction: ['name', 'price', 'bidCount', 'timeLeft', 'bidButton'],
  bidding: [
    'name',
    'price',
    'bidCount',
    'bidStatus',
    'timeLeft',
    'addToCartButton',
  ],
  buynow: ['name', 'price', 'addToCartButton'],
  default: ['name', 'price'],
  expired: ['name', 'dateWon', 'price', 'paymentExpiredDate'],
  paid: ['name', 'dateWon', 'price', 'paymentExpiredDate'],
  recent: ['name', 'price', 'bidCount', 'endDate'],
  unpaid: [
    'name',
    'dateWon',
    'price',
    'paymentExpiredDate',
    'paymentTimeLeft',
    'addToCartButton',
  ],
};

const SaleTableColumns = [
  {
    accessor: 'name',
    disableSortBy: true,
    id: 'name',
    Cell: ({ row, value }) => {
      const collection = useContext(CollectionContext);
      return (
        <div className="tw-flex tw-items-center tw-relative">
          <Link
            className="tw-relative tw-self-stretch tw-flex tw-items-center tw-text-secondary-dark visited:tw-text-secondary tw-font-bold group-hover:tw-underline active:tw-text-secondary-dark after:tw-content-[''] after:tw-absolute after:tw-block after:tw-w-[9999px] after:tw-h-full"
            to={`${row.original.permalink}${
              collection ? `?ref=${collection.slug}` : ''
            }`}>
            {value}
          </Link>
          {HandshakeTLDs.includes(row.original.tld) && (
            <>
              &nbsp;&nbsp;
              <Badge className="tw-relative tw-bg-secondary-dark tw-text-white tw-align-middle tw-text-sm tw-no-underline tw-scale-75 tw-origin-left tw-pointer-events-none">
                <div className="tw-pt-[1px]">Handshake</div>
              </Badge>
            </>
          )}
        </div>
      );
    },
    Header: 'Domain',
  },
  {
    accessor: 'endDate',
    csvHeader: 'endDate',
    id: 'endDate',
    sortDescFirst: true,
    Header: 'End date',
    Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
  },
  {
    accessor: 'endDate',
    csvHeader: 'endDate',
    id: 'timeLeft',
    Cell: ({ value }) => <TimeLeft endedText="Ended" until={value} />,
    Header: 'Time left',
  },
  {
    accessor: 'price',
    filter: 'between',
    id: 'price',
    Cell: ({ value }) => value && formatCurrency(value),
    Filter: props => {
      const match = useRouteMatch();
      const { data } = useQuery(queryDefs.marketStats);
      const max = useMemo(() => {
        let result = 1000;
        if (data) {
          const buyPriceP95 = 100000;
          const p95price =
            match.path === '/buynow'
              ? buyPriceP95
              : match.path === '/auctions'
              ? data.marketStats.auctionPriceP95
              : Math.max(data.marketStats.auctionPriceP95, buyPriceP95);
          const nearestPowerOf10 = Math.pow(
            10,
            Math.floor(Math.log10(p95price)),
          );
          const x = Math.max(nearestPowerOf10, 1000);
          result = Math.ceil(p95price / x) * x;
        }
        return result;
      }, [data, match.path]);
      return <SliderFilterCurrency {...props} min={0} max={max} softMaximum />;
    },
    Header: 'Price (USD)',
  },
  {
    accessor: 'bidCount',
    id: 'bidCount',
    Header: 'Bids',
    sortDescFirst: true,
  },
  {
    accessor: 'paymentExpiredDate',
    id: 'paymentTimeLeft',
    Cell: ({ value }) => {
      if (!value) return '';
      const expiredDate = new Date(value);
      if (expiredDate < new Date()) {
        return '';
      }
      return <TimeLeft until={value} />;
    },
    Header: 'Time left to pay',
  },
  {
    accessor: 'paymentExpiredDate',
    id: 'paymentExpiredDate',
    Cell: ({ row, value }) => {
      if (!value) return '';
      const { isPaid } = row.original;
      const expiredDate = new Date(value);
      return (
        <span
          className={cx(
            'tw-inline-flex tw-items-center',
            isPaid ? 'tw-text-blue' : 'tw-text-red-500',
          )}>
          {dayjs(expiredDate).format('MMM D, YYYY')}
          {isPaid === true && (
            <i className={`gb-icon gb-icon-checkmark tw-ml-2`} />
          )}
          {isPaid === false && expiredDate < new Date() && (
            <i className={`gb-icon gb-icon-close tw-ml-2`} />
          )}
        </span>
      );
    },
    Header: 'Payment due',
  },
  {
    accessor: 'tld',
    filter: 'includesSome',
    id: 'tld',
    Filter: TLDFilter,
    Header: 'TLD',
  },
  {
    accessor: 'nameLength',
    filter: 'between',
    id: 'nameLength',
    Filter: props => <SliderFilter {...props} min={0} max={40} softMaximum />,
    Header: 'Name length',
  },
  {
    accessor: row => !Boolean(row.name.match(/[0-9]+/)),
    filter: facetFilterType,
    id: 'noNumbers',
    Filter: props => (
      <FacetFilter
        {...props}
        disabled={
          props.state.filters.find(f => f.id === 'onlyNumbers')?.value === true
        }
      />
    ),
    Header: 'No numbers',
  },
  {
    accessor: row => !Boolean(row.name.split('.')[0].match(/[^0-9]+/)),
    filter: facetFilterType,
    id: 'onlyNumbers',
    Filter: props => (
      <FacetFilter
        {...props}
        disabled={
          props.state.filters.find(f => f.id === 'noNumbers')?.value === true
        }
      />
    ),
    Header: 'Only numbers',
  },
  {
    accessor: row => !Boolean(row.name.match('-')),
    filter: facetFilterType,
    id: 'noHyphens',
    Filter: FacetFilter,
    Header: 'No hyphens',
  },
  {
    filter: facetFilterType,
    id: 'nsfw',
    Filter: FacetFilter,
    Header: 'NSFW names',
  },
  {
    accessor: 'bidCount',
    filter: facetFilterType,
    id: 'hasBids',
    filterQuery: 'bidCount',
    Filter: BidCountFacetFilter,
    Header: 'Has bids',
  },
  {
    accessor: row => dayjs(row.endDate).format('MMM D, YYYY'),
    id: 'dateWon',
    Header: 'Date won',
  },
  {
    accessor: 'ageYears',
    id: 'age',
    filter: 'between',
    Filter: props => (
      <SliderFilter
        min={0}
        max={dayjs().diff('1995-01-01', 'years')}
        softMaximum
        suffix="years"
        {...props}
      />
    ),
    Header: 'Age (years)',
  },
  {
    accessor: 'estibotValue',
    id: 'estibotValue',
    filter: 'between',
    Filter: props => (
      <SliderFilterCurrency {...props} min={0} max={10000} softMaximum />
    ),
    Header: 'Estibot value',
  },
  {
    id: 'extensionsTaken',
    filter: 'between',
    Filter: props => <SliderFilter {...props} min={0} max={100} softMaximum />,
    Header: 'Extensions taken',
  },
  {
    accessor: 'keywordSearchCount',
    id: 'keywordSearchCount',
    filter: 'between',
    Filter: props => <SliderFilter {...props} min={0} max={1000} softMaximum />,
    Header: 'Keyword search count',
  },
  {
    accessor: 'backlinksCount',
    filter: 'between',
    id: 'backlinksCount',
    Filter: props => <SliderFilter {...props} min={0} max={1000} softMaximum />,
    Header: 'Backlinks',
  },
  {
    canExport: false,
    id: 'bidButton',
    Cell: ({ row }) => {
      if (row.original.status === 'active') {
        return (
          <div className="tw-hidden md:tw-flex tw-justify-end">
            <Button component={Link} to={row.original.permalink} size="small">
              Bid Now
            </Button>
          </div>
        );
      }
      return null;
    },
    Header: () => '',
  },
  {
    canExport: false,
    id: 'addToCartButton',
    Cell: ({ row }) => {
      if (
        row.original.saleType === 'buy' ||
        row.original.selfHasActiveInvoice
      ) {
        return (
          <div className="tw-flex tw-justify-end">
            <AddToCartButton sales={[row.original]}>
              {({ busy, onClick, inCart }) => (
                <div className="tw-relative">
                  <button
                    className={cx(
                      'tw-w-40 tw-scale-95 gb-btn gb-btn--primary gb-btn--sm',
                      {
                        'tw-cursor-wait': busy,
                      },
                    )}
                    disabled={busy || inCart}
                    onClick={onClick}>
                    <div className="tw-flex tw-items-center tw-justify-center">
                      <i className="gb-icon gb-icon-morpheus-cart-add tw-mr-2" />
                      Add to cart
                    </div>
                  </button>
                  {inCart ? (
                    <div className="gb-btn gb-btn--sm tw-absolute tw-inset-0 tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-white tw-shadow-none tw-text-secondary tw-pointer-events-none tw-rounded-none">
                      <div className="tw-flex tw-items-center tw-justify-center">
                        <i className="gb-icon gb-icon-morpheus-cart-success tw-mr-1" />
                        Added
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </AddToCartButton>
          </div>
        );
      }
      return null;
    },
    Header: () => '',
  },
  {
    id: 'bidStatus',
    Header: 'Status',
    Cell: ({ row }) => {
      if (row.original.status === 'cancelled') {
        return 'Cancelled';
      }
      if (row.original.selfIsLeadingBidder) {
        return row.original.isEnded ? 'Won' : 'Winning';
      }
      if (row.original.bidCount > 0) {
        return 'Outbid';
      }
      return '';
    },
  },
];
export default SaleTableColumns;
