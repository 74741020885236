import { Link } from 'react-router-dom';
import Meta from '../components/Meta';
import { SaleTableColumnSets } from '../components/SaleTable.columns';
import SaleTablePage from '../components/SaleTablePage';
import queryDefs from '../lib/queryDefs';

const defaultTableState = {
  sortBy: [{ id: 'bidCount', desc: true }],
};

export default function Auctions() {
  return (
    <>
      <Meta
        canonical="https://www.namecheap.com/market/"
        description="Discover domains for sale in our Domain Auctions. Find the perfect name for your brand, and try domain name bidding today."
        robots="all"
        title="Bid On Domain Names | Domain Name Auctions - Namecheap"
      />
      <SaleTablePage
        emptyResultsDescription={
          <>
            We weren’t able to find any domains matching the specified criteria.
            Try again using different search filters, or try searching for{' '}
            <Link to="/buynow/">Buy Now domains</Link>.
          </>
        }
        fetchPolicy="cache-and-network"
        nextFetchPolicy="cache-and-network"
        defaultTableState={defaultTableState}
        query={queryDefs.saleTable}
        queryVars={{}}
        title="Auctions"
        visibleColumns={() => SaleTableColumnSets.auction}
      />
      <div className="tw-text-sm tw-text-center tw-mt-5 tw-pb-8 tw-px-2 tw-leading-tight">
        <a
          href={`https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.amazonaws.com/reports/Namecheap_Market_Sales.csv`}>
          Export all current domain auctions to CSV
        </a>{' '}
        <small className="tw-text-xs tw-whitespace-nowrap">
          (Updates once per hour)
        </small>
      </div>
    </>
  );
}
