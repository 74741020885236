import {
  createContext,
  lazy,
  useEffect,
  useMemo,
  useRef,
  Suspense,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { syncCartItems } from '../lib/cartItemsVar';
import getAuthRedirectUrl from '../lib/getAuthRedirectUrl';
import getSalePresenter from '../lib/getSalePresenter';
import isAuthError from '../lib/isAuthError';
import isInvalidQueryError from '../lib/isInvalidQueryError';
import isLoggedInVar from '../lib/isLoggedInVar';
import muiTheme from '../lib/muiTheme';
import queryDefs from '../lib/queryDefs';
import useFavoriteMutation from '../lib/useFavoriteMutation';
import Auctions from '../pages/Auctions';
import BuyNow from '../pages/BuyNow';
import Home from '../pages/Home';
import AppDialog, { openAppDialog } from './AppDialog';
import AppStatus from './AppStatus';
import Loader from './Loader';
import Nav from './Nav';
import StickyAlerts, { appendStickyAlert } from './StickyAlerts';
import AppCTA from './AppCTA';
import Layout from './Layout';

// Lazy load components
const Collection = lazy(() => import('../pages/Collection'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Login = lazy(() => import('../pages/Login'));
const Logout = lazy(() => import('../pages/Logout'));
const Newsletter = lazy(() => import('../pages/Newsletter'));
const Sale = lazy(() => import('../pages/Sale'));
const Subscribe = lazy(() => import('../pages/Subscribe'));

// Create contexts
export const TLDsContext = createContext(null);
export const UserContext = createContext(null);

export default function App() {
  const previousLocation = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [tlds, setTLDs] = useState({});
  const [favorite, favoriteQuery] = useFavoriteMutation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const {
    data: userContextData,
    error: userContextError,
    previousData: previousUserContextData,
    refetch: refetchUserContext,
    startPolling: startPollingUserContext,
    stopPolling: stopPollingUserContext,
  } = useQuery(queryDefs.userContext, {
    fetchPolicy: 'no-cache',
    onError: error => {
      if (isAuthError(error)) {
        isLoggedInVar(false);
      }
    },
    onCompleted: data => {
      // This callback only runs on initial, not when polling
      if (data?.self) {
        isLoggedInVar(true);
      } else if (data.self === null) {
        isLoggedInVar(false);
      }
    },
  });

  // Scroll to the top of the page except when going back!
  useEffect(() => {
    const isBack = history.action === 'POP';
    const isSamePathname =
      previousLocation.current?.pathname === location.pathname;
    const isInitial = !previousLocation.current;

    if (isInitial || !(isBack || isSamePathname)) {
      const root = document.getElementById('root');
      if (root && root.getBoundingClientRect().top < 0) {
        root.scrollIntoView();
      }
    }

    previousLocation.current = location;
  }, [history, location]);

  // Handle watch auth redirects
  useEffect(() => {
    if (params.get('watch') && !favoriteQuery.loading) {
      favorite({
        add: params.get('watch').split(','),
      })
        .then(() => {
          params.delete('watch');
          history.replace({
            ...location,
            search: `?${params.toString()}`,
          });
        })
        .catch(() => {
          window.alert(`Failed to watch domain.`);
        });
    }
  }, [params, history, location, favorite, favoriteQuery.loading]);

  // Poll user context
  useEffect(() => {
    if (isLoggedIn === true) {
      startPollingUserContext(15 * 1000);
    } else if (isLoggedIn === false) {
      stopPollingUserContext();
    } else if (userContextError && isInvalidQueryError(userContextError)) {
      stopPollingUserContext();
    }
  }, [
    isLoggedIn,
    refetchUserContext,
    startPollingUserContext,
    stopPollingUserContext,
    userContextError,
  ]);

  // Initialize cartItemsVar
  useEffect(() => {
    syncCartItems();
  }, []);

  // Show sticky alerts
  useEffect(() => {
    // Unpaid invoices
    if (
      userContextData?.self?.unpaid.total > 0 &&
      userContextData?.self?.unpaid.total !==
        previousUserContextData?.self?.unpaid.total
    ) {
      appendStickyAlert('unpaidInvoices');
    }

    if (previousUserContextData) {
      // Find any outbid sales
      const outbidSales =
        userContextData?.self?.bidding?.items?.filter(sale => {
          const previousSaleData =
            previousUserContextData.self?.bidding?.items?.find(
              prev => prev.id === sale.id,
            );
          return (
            previousSaleData?.selfIsLeadingBidder === true &&
            sale.selfIsLeadingBidder === false &&
            sale.status === 'active' &&
            sale.bids[1]?.user.isSelf &&
            sale.bids[1].status === 'processed'
          );
        }) ?? [];

      outbidSales.forEach(sale => {
        appendStickyAlert('outbid', {
          id: `outbid:sale(${sale.id})`,
          sale: getSalePresenter(sale),
        });
      });
    }

    if (userContextData?.self === null && isLoggedIn) {
      isLoggedInVar(false);
    }
  }, [userContextData, previousUserContextData, isLoggedIn]);

  // show display name solicitation modal on page load
  useEffect(() => {
    if (
      userContextData?.self?.dismissedMessages.includes(
        'solicit_display_name',
      ) === false &&
      !userContextData.self.displayName.updatedDate
    ) {
      openAppDialog('solicitDisplayName');
    }
  }, [userContextData]);

  // Fetch tlds
  useEffect(() => {
    const fetchTLDs = async () => {
      const response = await fetch(
        `https://dpv7itlzdgya8.cloudfront.net/generateTLDs`,
      );
      if (response.ok) {
        const data = await response.json();
        setTLDs(data);
      } else {
        setTLDs(null);
      }
    };

    fetchTLDs();
  }, []);

  const userContextValue = useMemo(
    () => ({
      ...userContextData?.self,
      bidding: userContextData?.self?.bidding?.items?.map(sale => sale.id),
      watching: userContextData?.self?.watchedProducts?.map(
        product => product.name,
      ),
    }),
    [userContextData],
  );

  return (
    <ThemeProvider theme={createTheme(adaptV4Theme(muiTheme))}>
      <StyledEngineProvider injectFirst>
        <UserContext.Provider value={userContextValue}>
          <TLDsContext.Provider value={tlds}>
            <Layout>
              <AppDialog />
              <StickyAlerts />
              <Nav />
              <AppStatus />
              <AppCTA />
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route
                    path="/collection/:slug"
                    render={({ match }) => (
                      <Redirect to={`/${match.params.slug}`} />
                    )}
                  />
                  <Route path="/auctions">
                    <Auctions />
                  </Route>
                  <Route path="/buynow/:bindomain">
                    <Sale />
                  </Route>
                  <Route path="/buynow">
                    <BuyNow />
                  </Route>
                  <Route
                    path="/dashboard"
                    children={({ location }) => {
                      // We don't know yet if the user is logged in
                      if (isLoggedIn === null) {
                        return null;
                      }
                      if (isLoggedIn) {
                        return <Dashboard />;
                      } else {
                        window.location.replace(
                          getAuthRedirectUrl({ returnTo: location.pathname }),
                        );
                        return <Loader />;
                      }
                    }}
                  />
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/logout">
                    <Logout />
                  </Route>
                  <Route path="/newsletter">
                    <Newsletter />
                  </Route>
                  <Route path="/sale/:id/:name?">
                    <Sale />
                  </Route>
                  <Route path="/subscribe">
                    <Subscribe />
                  </Route>
                  <Route
                    path="/:slug"
                    children={({ match }) => {
                      if (/\w+(\.\w+)+/.test(match.params.slug)) {
                        return <Sale />;
                      }
                      return <Collection />;
                    }}
                  />
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Suspense>
            </Layout>
          </TLDsContext.Provider>
        </UserContext.Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
