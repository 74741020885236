import { useMutation } from '@apollo/client';
import getAuthRedirectUrl from './getAuthRedirectUrl';
import isAuthError from './isAuthError';
import queryDefs from './queryDefs';

export default function useFavoriteMutation() {
  const [favorite, favoriteQuery] = useMutation(queryDefs.favorite, {
    refetchQueries: ['UserFavorites'],
  });

  const callback = async ({ add = [], remove = [] }) => {
    try {
      await favorite({
        variables: { watch: add, unwatch: remove },
      });
    } catch (error) {
      if (isAuthError(error)) {
        window.location.href = getAuthRedirectUrl({
          returnTo: `${window.location.pathname}?watch=${add.join(',')}`,
        });
      } else {
        throw error;
      }
    }
  };

  /**
   * @type {[typeof callback, typeof favoriteQuery]}
   */
  const result = [callback, favoriteQuery];

  return result;
}
