/**
 *
 * @param {{ returnTo?: string; defaultToSignup?: boolean; }} opts
 * @returns
 */
export default function getAuthRedirectUrl(
  opts = { returnTo: '', defaultToSignup: false },
) {
  const { origin } = window.location;

  let result =
    `https://${process.env.REACT_APP_SSO_DOMAIN}` +
    `/connect/authorize` +
    `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}` +
    `&redirect_uri=${origin}/market/login/` +
    `&response_type=code` +
    `&scope=openid offline_access productPlatform.accountBalance.read`;

  if (opts.returnTo) {
    result += `&state=${opts.returnTo}`;
  }

  if (opts.defaultToSignup) {
    result += `&prompt=create`;
  }

  return result;
}
