import { useState } from 'react';
import Loader from './Loader';

/**
 * @param {{ onClick: () => Promise<void> }} props
 * @returns
 */
export default function SaleTableFetchMoreButton({ onClick }) {
  const [loading, setLoading] = useState(false);
  return (
    <button
      className="gb-btn gb-btn--lg gb-btn--block"
      type="button"
      onClick={() => {
        setLoading(true);
        onClick().then(() => setLoading(false));
      }}>
      {loading ? <Loader className="tw-pt-0" message="" /> : 'Load More'}
    </button>
  );
}
