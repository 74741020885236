import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';
import { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { useAsyncDebounce } from 'react-table';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { TLDsContext } from './App';
import Select from './Select';
import Slider from './Slider';
import TextInput from './TextInput';

const DEBOUNCE_RATE = 200;

export function SliderFilter({
  column: { filterValue, setFilter },
  min = 0,
  max = 1000,
  prefix,
  softMaximum = false,
  suffix,
}) {
  const setFilterDebounced = useAsyncDebounce(value => {
    setFilter(value);
  }, DEBOUNCE_RATE);
  const parseToLocalValue = useCallback(
    filterValue =>
      filterValue ? [filterValue[0], filterValue[1] || max] : [min, max],
    [min, max],
  );
  const isValueAtSoftLimit = useCallback(
    value => softMaximum && value === max,
    [max, softMaximum],
  );
  const [localValue, setLocalValue] = useState(parseToLocalValue(filterValue));
  const [inputValue, setInputValue] = useState(localValue);
  const step = useMemo(
    () => Math.max(1, Math.pow(10, Math.floor(Math.log10(max)) - 2)),
    [max],
  );
  const update = value => {
    if (value[0] === min && value[1] === max) {
      setFilterDebounced(undefined);
    } else {
      setFilterDebounced([
        value[0],
        isValueAtSoftLimit(value[1]) ? undefined : value[1],
      ]);
    }
  };

  useEffect(() => {
    const parsedValue = parseToLocalValue(filterValue);
    setLocalValue(parsedValue);
    setInputValue(parsedValue);
  }, [filterValue, parseToLocalValue]);

  return (
    <>
      <Slider
        className="tw-relative tw-z-10"
        max={max}
        min={min}
        onChange={(_event, value) => {
          setLocalValue(value);
          setInputValue(value);
          update(value);
        }}
        value={localValue}
      />
      {prefix}
      <div className="tw-inline-block tw-relative tw-mr-1 tw-group hover:tw-text-opacity-0 focus-within:tw-text-opacity-0">
        <input
          className="tw-absolute tw-inset-0 tw-w-full tw-border tw-border-transparent hover:tw-border-gray-100 focus:tw-border-gray-100"
          min={min}
          max={localValue[1]}
          onBlur={() => {
            update(localValue);
            setInputValue(localValue);
          }}
          onChange={event => {
            const newInputValue = +event.target.value;
            // Make sure the value stays above the min prop
            let newLocalValue = Math.max(min, +event.target.value);
            // Make sure the value stays below the upper limit
            newLocalValue = Math.min(localValue[1], newLocalValue);
            // Commit the change
            setLocalValue([newLocalValue, localValue[1]]);
            setInputValue([newInputValue, inputValue[1]]);
          }}
          onKeyUp={event => event.key === 'Enter' && event.currentTarget.blur()}
          step={step}
          type="number"
          value={String(inputValue[0])}
        />
        <span className="tw-text-transparent tw-pl-1 tw-relative tw-pointer-events-none">
          {inputValue[0].toLocaleString()}
        </span>
        <span className="tw-pl-2 tw-inline-block tw-relative tw-pointer-events-none tw-font-bold tw-text-gray-400 tw-select-none group-hover:tw-text-transparent">
          –
        </span>
      </div>
      <div className="tw-inline-block tw-relative tw-pr-5">
        <input
          className="tw-absolute tw-inset-0 tw-w-full tw-border tw-border-transparent hover:tw-border-gray-100 focus:tw-border-gray-100"
          min={localValue[0]}
          max={max}
          onBlur={event => {
            update(localValue);
            setInputValue(localValue);
          }}
          onChange={event => {
            const newInputValue = +event.target.value;
            // Make sure the value stays below the max prop
            let newLocalValue = Math.min(max, newInputValue);
            // Make sure the value stays above the lower limit
            newLocalValue = Math.max(localValue[0], newLocalValue);
            // Commit the change
            setLocalValue([localValue[0], newLocalValue]);
            setInputValue([inputValue[0], newInputValue]);
          }}
          onKeyUp={event => event.key === 'Enter' && event.currentTarget.blur()}
          step={step}
          type="number"
          value={String(inputValue[1])}
        />
        <span className="tw-text-transparent tw-pl-1 tw-relative tw-pointer-events-none">
          {inputValue[1].toLocaleString()}
        </span>
        <span className="tw-inline-block tw-relative tw-pointer-events-none tw-select-none">
          {suffix && <>&nbsp;{suffix}</>}
          <span
            className={isValueAtSoftLimit(inputValue[1]) ? '' : 'tw-invisible'}>
            +
          </span>
        </span>
      </div>
    </>
  );
}

export function SliderFilterCurrency(props) {
  return <SliderFilter {...props} prefix="$" />;
}

export function SliderFilterPercentage(props) {
  return <SliderFilter {...props} min={0} max={100} suffix="%" />;
}

export function SelectFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = _uniq(preFilteredRows.map(row => row.values[id])).sort();
  return (
    <Select
      className="tw-w-full"
      onChange={event =>
        setFilter(event.target.value ? [event.target.value] : [])
      }
      value={filterValue}>
      <option value="">Any</option>
      {options.map(option => (
        <option key={option}>{option}</option>
      ))}
    </Select>
  );
}

export function facetFilterType(rows, ids, filterValue) {
  if (!filterValue) {
    return rows;
  }
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id];
      return rowValue === true;
    });
  });
}

export function FacetFilter({
  column: { id, filterValue, Header, setFilter },
  disabled = false,
}) {
  return (
    <div className="gb-checkbox gb-checkbox--sm">
      <input
        checked={Boolean(filterValue)}
        className="gb-checkbox__input"
        disabled={disabled}
        onChange={event =>
          event.target.checked ? setFilter(true) : setFilter(undefined)
        }
        id={id}
        type="checkbox"
      />
      <label
        htmlFor={id}
        className="gb-checkbox__label tw-font-light tw-text-sm tw-leading-relaxed">
        <span className="tw-relative tw--top-px tw-text-gray-600">
          {Header}
        </span>
      </label>
    </div>
  );
}

export function ExactNumberFilter({ column: { filterValue, setFilter } }) {
  return (
    <TextInput
      className="tw-w-20"
      min="1"
      onChange={event =>
        setFilter(event.target.value ? Number(event.target.value) : undefined)
      }
      type="number"
      value={filterValue}
    />
  );
}

const CustomPopperComponent = props => (
  <Popper
    {...props}
    modifiers={[
      {
        name: 'flip',
        enabled: false,
      },
    ]}
  />
);

export function TLDFilter({ column: { filterValue, setFilter } }) {
  const [localValue, setLocalValue] = useState(filterValue || []);
  const tlds = useContext(TLDsContext);

  const options = useMemo(() => {
    const { Other = [], International = [], ...otherTlds } = tlds || {};
    return _flatMap(
      {
        Popular: [
          'ai',
          'club',
          'com',
          'cc',
          'co',
          'info',
          'io',
          'link',
          'live',
          'me',
          'net',
          'online',
          'org',
          'site',
          'us',
          'xyz',
        ],
        ...otherTlds,
        International,
        Other,
      },
      (items, key) => items.map(item => ({ value: item, category: key })),
    );
  }, [tlds]);

  const setFilterDebounced = useAsyncDebounce(value => {
    setFilter(value);
  }, DEBOUNCE_RATE);

  const update = value => {
    const uniqueValue = _uniq(value);
    setLocalValue(uniqueValue);
    setFilterDebounced(uniqueValue);
  };

  useEffect(() => {
    if (!filterValue) {
      setLocalValue([]);
    }
  }, [filterValue]);

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center">
      <Autocomplete
        className="tw-w-full"
        classes={{
          paper: 'tw-text-sm',
        }}
        getOptionLabel={option => option.value}
        groupBy={option => option.category}
        multiple
        onChange={(_event, options) => {
          update(options.map(option => option.value));
        }}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            color="secondary"
            placeholder={
              options.length > 0
                ? `Search ${options.length.toLocaleString()} TLDs…`
                : ''
            }
            size="small"
            variant="outlined"
          />
        )}
        value={localValue
          .map(value => options.find(option => option.value === value))
          .filter(value => !!value)}
        PopperComponent={CustomPopperComponent}
      />
    </div>
  );
}

export function BidCountFacetFilter(props) {
  return (
    <FacetFilter
      {...props}
      column={{
        ...props.column,
        setFilter: value => {
          props.column.setFilter(value ? { min: 1 } : undefined);
        },
      }}
    />
  );
}
