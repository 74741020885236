import Meta from '../components/Meta';
import { SaleTableColumnSets } from '../components/SaleTable.columns';
import SaleTablePage from '../components/SaleTablePage';
import queryDefs from '../lib/queryDefs';

export default function BuyNow() {
  return (
    <>
      <Meta
        canonical="https://www.namecheap.com/market/"
        description="Find domain names for sale and purchase them right away in our Buy Now section. It’s your chance to get a great deal today."
        robots="all"
        title="Buy premium domains - Namecheap"
      />
      <SaleTablePage
        fetchPolicy="cache-first"
        hiddenFilters={['nsfw']}
        nextFetchPolicy="cache-first"
        query={queryDefs.buyNowTable}
        salesPath="binSales"
        showAddToCart={true}
        showWatch={false}
        title="Buy Now"
        visibleColumns={SaleTableColumnSets.buynow}
      />
      <div className="tw-text-sm tw-text-center tw-mt-5 tw-pb-8 tw-px-2 tw-leading-tight">
        <a
          href={`https://${process.env.REACT_APP_AWS_S3_BUCKET}.s3.amazonaws.com/reports/Namecheap_Market_Sales_Buy_Now.csv`}>
          Export all buy now domains to CSV
        </a>{' '}
        <small className="tw-text-xs tw-whitespace-nowrap">
          (Updates once per hour)
        </small>
      </div>
    </>
  );
}
